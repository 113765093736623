import React from "react";
import "./Projects.css";
import ProjectItem from "./ProjectItem";

// make an array for projects
const projects = [
  {
    imageUrl: "img/horizon-peaks.webp",
    title: "Horizon Peaks",
    preview: "https://horizon-peaks.ahmedsaidadnan.com",
    repo: "https://github.com/AhmeddSaid",
    id: 1,
  },
  {
    imageUrl: "img/xena.webp",
    title: "Xena Perfume",
    preview: "https://xenaperfume.com/",
    repo: "https://github.com/AhmeddSaid",
    id: 2,
  },
  {
    imageUrl: "img/smash.webp",
    title: "Smash Digital Solutions",
    preview: "https://smash.ahmedsaidadnan.com",
    repo: "https://github.com/AhmeddSaid",
    id: 3,
  },
  {
    imageUrl: "img/gpitco.webp",
    title: "GPITCO IT Solutions",
    preview: "https://gpitco.ahmedsaidadnan.com",
    repo: "https://github.com/AhmeddSaid",
    id: 4,
  },
  {
    imageUrl: "img/pizza.webp",
    title: "Fast React Pizza",
    preview: "https://ahmeddsaid.github.io/fast-react-pizza",
    repo: "https://github.com/AhmeddSaid/fast-react-pizza",
    id: 5,
  },
  {
    imageUrl: "img/worldwise.webp",
    title: "World Wise",
    preview: "https://ahmeddsaid.github.io/worldwise",
    repo: "https://github.com/AhmeddSaid/worldwise",
    id: 6,
  },
  {
    imageUrl: "img/usepopcorn.webp",
    title: "usePopcorn",
    preview: "https://ahmeddsaid.github.io/usePopcorn",
    repo: "https://github.com/AhmeddSaid/usePopcorn",
    id: 7,
  },
  {
    imageUrl: "img/reactquiz.webp",
    title: "React Quiz",
    preview: "https://ahmeddsaid.github.io/react-quiz",
    repo: "https://github.com/AhmeddSaid/react-quiz",
    id: 8,
  },

  {
    imageUrl: "img/travellist.webp",
    title: "Travel List",
    preview: "https://ahmeddsaid.github.io/travel-list",
    repo: "https://github.com/AhmeddSaid/travel-list",
    id: 9,
  },
  {
    imageUrl: "img/leon.webp",
    title: "Leon Template",
    preview: "https://ahmeddsaid.github.io/Leon",
    repo: "https://github.com/AhmeddSaid/Leon",
    id: 10,
  },
  {
    imageUrl: "img/kasper.webp",
    title: "Kasper Template",
    preview: "https://ahmeddsaid.github.io/Kasper",
    repo: "https://github.com/AhmeddSaid/Kasper",
    id: 11,
  },
  {
    imageUrl: "img/lavender.webp",
    title: "Lavender Template",
    preview: "https://ahmeddsaid.github.io/Lavender",
    repo: "https://github.com/AhmeddSaid/Lavender",
    id: 12,
  },
  {
    imageUrl: "img/bondi.webp",
    title: "Bondi Template",
    preview: "https://ahmeddsaid.github.io/Bondi",
    repo: "https://github.com/AhmeddSaid/Bondi",
    id: 13,
  },
  {
    imageUrl: "img/buildcon.webp",
    title: "BuildCon",
    preview: "https://ahmeddsaid.github.io/buildcon",
    repo: "https://github.com/AhmeddSaid/buildcon",
    id: 14,
  },
  {
    imageUrl: "img/dashboard.webp",
    title: "Dashboard",
    preview: "https://ahmeddsaid.github.io/Dashboard-Project",
    repo: "https://github.com/AhmeddSaid/Dashboard-Project",
    id: 15,
  },
  {
    imageUrl: "img/fyc.webp",
    title: "FYC Website",
    preview: "https://ahmeddsaid.github.io/follow-your-child",
    repo: "https://github.com/AhmeddSaid/follow-your-child",
    id: 16,
  },
  {
    imageUrl: "img/artcladding.webp",
    title: "Art Cladding",
    preview: "https://ahmeddsaid.github.io/art-cladding",
    repo: "https://github.com/AhmeddSaid/art-cladding",
    id: 17,
  },
  {
    imageUrl: "img/reactweather.webp",
    title: "React Weather App",
    preview: "https://ahmeddsaid.github.io/react-weather-app",
    repo: "https://github.com/AhmeddSaid/react-weather-app",
    id: 18,
  },
  {
    imageUrl: "img/books.webp",
    title: "MyReads App",
    preview: "https://ahmeddsaid.github.io/react-books-library",
    repo: "https://github.com/AhmeddSaid/react-books-library",
    id: 19,
  },
  {
    imageUrl: "img/mythical.webp",
    title: "Mythical 3D Slider",
    preview: "https://mythical.ahmedsaidadnan.com",
    repo: "https://github.com/AhmeddSaid",
    id: 20,
  },
  {
    imageUrl: "img/stories.webp",
    title: "Stories",
    preview: "https://ahmeddsaid.github.io/stories",
    repo: "https://github.com/AhmeddSaid/stories",
    id: 21,
  },

  {
    imageUrl: "img/parallax.webp",
    title: "Parallax Website",
    preview: "https://ahmeddsaid.github.io/parallax",
    repo: "https://github.com/AhmeddSaid/parallax",
    id: 22,
  },
  {
    imageUrl: "img/todo.webp",
    title: "TO-DO List",
    preview: "https://ahmeddsaid.github.io/TO-DO",
    repo: "https://github.com/AhmeddSaid/TO-DO",
    id: 23,
  },
  // {
  //   imageUrl: "img/mathgame.webp",
  //   title: "React Math Game",
  //   preview: "https://ahmeddsaid.github.io/React-math-quiz",
  //   repo: "https://github.com/AhmeddSaid/React-math-quiz",
  //   id: 24,
  // },
  {
    imageUrl: "img/typing.webp",
    title: "Typing Game",
    preview: "https://ahmeddsaid.github.io/typing-game",
    repo: "https://github.com/AhmeddSaid/typing-game",
    id: 25,
  },
];

const projectsList = () =>
  projects.map((project) => (
    <ProjectItem
      key={project.id}
      imageUrl={project.imageUrl}
      title={project.title}
      preview={project.preview}
      repo={project.repo}
    />
  ));

const Projects = () => {
  return (
    <div className="projects-section" id="projects">
      <h2 className="main-title">Projects</h2>
      <div className="container">
        <div className="images-container">{projectsList()}</div>
      </div>
    </div>
  );
};

export default Projects;
