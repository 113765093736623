import React, { useEffect, useRef, useState } from "react";
import "./Skills.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faLightbulb,
  faLaptop,
} from "@fortawesome/free-solid-svg-icons";
import { faCodepen } from "@fortawesome/free-brands-svg-icons";

const Skills = () => {
  const myRef = useRef();
  const [skills, setSkills] = useState([
    { name: "HTML", width: "0%", className: "html-skill" },
    { name: "CSS", width: "0%", className: "css-skill" },
    { name: "JavaScript", width: "0%", className: "js-skill" },
    { name: "Bootstrap", width: "0%", className: "bootstrap-skill" },
    { name: "React", width: "0%", className: "react-skill" },
    { name: "TypeScript", width: "0%", className: "ts-skill" },
    { name: "Tailwind", width: "0%", className: "tailwind-skill" },
  ]);
  const [isVisible, setIsVisible] = useState();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsVisible(entry.isIntersecting);
    });
    observer.observe(myRef.current);
    if (isVisible) {
      setSkills((prevSkills) =>
        prevSkills.map((skill) => {
          switch (skill.name) {
            case "HTML":
              return { ...skill, width: "90%" };
            case "CSS":
              return { ...skill, width: "90%" };
            case "JavaScript":
              return { ...skill, width: "85%" };
            case "Bootstrap":
              return { ...skill, width: "75%" };
            case "React":
              return { ...skill, width: "90%" };
            case "TypeScript":
              return { ...skill, width: "75%" };
            case "Tailwind":
              return { ...skill, width: "90%" };
            default:
              return skill;
          }
        })
      );
    } else {
      setSkills((prevSkills) =>
        prevSkills.map((skill) => ({ ...skill, width: "0%" }))
      );
    }
  }, [isVisible]);

  return (
    <div className="skills-section" id="skills">
      <h2 className="main-title">Skills</h2>
      <div className="container">
        <div className="box-wrapper">
          <div className="box">
            <div className="icon-wrap">
              <div className="hexagon bolt">
                <FontAwesomeIcon className="icon" icon={faBolt} />
              </div>
            </div>
            <div className="text">
              <div className="label">Fast</div>
              <div>
                Fast load times and lag-free interaction, my highest priority.
              </div>
            </div>
          </div>
          <div className="box">
            <div className="icon-wrap">
              <div className="hexagon laptop">
                <FontAwesomeIcon className="icon" icon={faLightbulb} />
              </div>
            </div>
            <div className="text">
              <div className="label">Intuitive</div>
              <div>Strong preference for easy to use, intuitive UX/UI.</div>
            </div>
          </div>
          <div className="box">
            <div className="icon-wrap">
              <div className="hexagon lightbulb">
                <FontAwesomeIcon className="icon" icon={faLaptop} />
              </div>
            </div>
            <div className="text">
              <div className="label">Responsive</div>
              <div>My layouts will work on any device, big or small.</div>
            </div>
          </div>
          <div className="box">
            <div className="icon-wrap">
              <div className="hexagon dynamic">
                <FontAwesomeIcon className="icon" icon={faCodepen} />
              </div>
            </div>
            <div className="text">
              <div className="label">Dynamic</div>
              <div>
                Websites shouldn't be static; I enjoy bringing pages to life.
              </div>
            </div>
          </div>
        </div>
        <div className="our-skills">
          <div className="container">
            <div className="skills" ref={myRef}>
              {skills.map((skill) => (
                <div className={`skill ${skill.className}`} key={skill.name}>
                  <h3>
                    {skill.name} <span>{skill.width}</span>
                  </h3>
                  <div className="progress">
                    <span
                      style={{ width: skill.width }}
                      className={skill.className}
                    ></span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
