import React from "react";
import TypewriterComponent from "typewriter-effect";
import Particle from "../Particles/Particle";
import "./Intro.css";

const Intro = () => {
  return (
    <div className="landing" id="intro">
      <Particle />
      <div className="container">
        <div className="text">
          <h1>
            Hi THERE! <br />
            I'M <span>AHMED SAID</span> <br />
            <span className="type-writer">
              <TypewriterComponent
                options={{
                  strings: [
                    "Front End Web Developer.",
                    "React Developer.",
                    "UI Developer.",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </span>
          </h1>
          <br />
          <p>
            I'm a passionate front-end developer focused on <br /> creating
            dynamic, responsive web applications. <br />I enjoy delivering
            high-quality, user-friendly solutions and continuously seek
            opportunities to grow and contribute to innovative projects.
          </p>
          <a
            href="resume/AhmedSaidResume.pdf"
            className="resume-holder"
            target="_blank"
          >
            {" "}
          </a>
        </div>
        {/* <div className="image">
          <img src="img/my-pic.webp" alt="" />
        </div> */}
      </div>
    </div>
  );
};

export default Intro;
