import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <p className="copyright">
        <span>Ahmed Said</span> &copy; 2022
      </p>
    </div>
  );
};

export default Footer;
